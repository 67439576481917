import { Dropdown, Menu, MenuItem, Tooltip } from '@hol-jsp/dashboard-dsl';
import { SideNavNavigationProps } from './SideNav';
import Link from 'next/link';
import { getOrgSettingsFromCookies } from 'helper';
import { useActionTracker } from '@/helper/actionTracker';
import { useActivityLog } from '@/modules/general/hooks';
import { useCallback } from 'react';

const CollapsedMenus = ({ nav }: { nav: SideNavNavigationProps[] }) => {
  const orgSettings = getOrgSettingsFromCookies();
  const { mutate } = useActivityLog();

  const useTrackedAction = (mutate: any) => {
    return useCallback(
      (params: any) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useActionTracker({
          ...params,
          mutate,
        });
      },
      [mutate]
    );
  };

  const trackAction = useTrackedAction(mutate);

  const handleClickMenu = (data: any) => {
    trackAction({
      action: 'click-side-navigation',
      type: 'side-navigation',
      category: 'Side Navigation',
      status: 'success',
      data,
    });
  };

  return (
    <div className="space-y-2">
      {nav
        ? nav.map((item, index) =>
            item.childNav ? (
              <Dropdown
                sideOffset={4}
                alignOffset={12}
                side="bottom"
                align="start"
                key={index}
                className="min-w-[160px] z-10"
                trigger={
                  <div
                    className="flex justify-center"
                    onClick={() => handleClickMenu(item)}
                  >
                    <Tooltip content={item.label} side="right">
                      <MenuItem
                        as={Link}
                        icon={item.icon}
                        active={item.active}
                        href={item.href}
                        child={false}
                        data-testid={item.testid}
                        label={item.label}
                        collapsed
                        style={{
                          backgroundColor: item.active
                            ? orgSettings?.sidenav_active_menu_background_color
                            : '',
                          color: item.active
                            ? orgSettings?.sidenav_active_text_and_icon_color
                            : orgSettings?.sidenav_text_and_icon_color,
                        }}
                        className="forceStyle"
                      />
                    </Tooltip>
                  </div>
                }
              >
                <Menu>
                  {item.childNav?.map((itemChild, index) => (
                    <div onClick={() => handleClickMenu(itemChild)} key={index}>
                      <MenuItem
                        as={Link}
                        icon={itemChild.icon}
                        active={itemChild.active}
                        href={itemChild.href}
                        data-testid={itemChild.testid}
                        label={itemChild.label}
                        child
                        style={{
                          backgroundColor: itemChild.active
                            ? orgSettings?.sidenav_active_menu_background_color
                            : '',
                          color: itemChild.active
                            ? orgSettings?.sidenav_active_text_and_icon_color
                            : orgSettings?.sidenav_text_and_icon_color,
                        }}
                        className="forceStyle"
                      />
                    </div>
                  )) ?? null}
                </Menu>
              </Dropdown>
            ) : (
              <div
                className="flex justify-center"
                key={index}
                onClick={() => handleClickMenu(item)}
              >
                <Tooltip content={item.label} side="right">
                  <MenuItem
                    as={Link}
                    icon={item.icon}
                    active={item.active}
                    href={item.href}
                    child={false}
                    data-testid={item.testid}
                    label={item.label}
                    collapsed
                    style={{
                      backgroundColor: item.active
                        ? orgSettings?.sidenav_active_menu_background_color
                        : '',
                      color: item.active
                        ? orgSettings?.sidenav_active_text_and_icon_color
                        : orgSettings?.sidenav_text_and_icon_color,
                    }}
                    className="forceStyle"
                  />
                </Tooltip>
              </div>
            )
          )
        : null}
    </div>
  );
};

export default CollapsedMenus;
