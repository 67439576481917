import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteItemProp,
  AutocompleteLabel,
  AutocompleteValue,
  Input,
  TextDS2,
} from '@hol-jsp/dashboard-dsl';
import { Combobox } from '@headlessui/react';
import tw from 'twin.macro';
import styled from '@emotion/styled';
import {
  MagnifyingGlass,
  CaretCircleRight,
  ClockCounterClockwise,
} from 'phosphor-react';
import { t } from 'i18next';
import classnames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { getRecentSearchFromStorage, urlWithSlug } from 'helper';
import { useRouter } from 'next/router';

export interface SearchProps {
  onClickSearch?: () => void;
  placeholder?: string;
  className?: string;
  isFocus?: boolean;
  setIsFocus: (isFocus: boolean) => void;
}

export type SearchTab = 'obligation' | 'regulation' | 'complianceFiles';
export interface IRecentSearch extends AutocompleteItemProp {
  tab: SearchTab;
}
const searchTabAndLabel = {
  obligation: 'Obligation',
  regulation: 'Regulation',
  complianceFiles: 'Compliance Files',
};

export function Search({
  isFocus,
  setIsFocus,
  className,
  ...props
}: SearchProps) {
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const pathname = router.asPath;
  const q = new URLSearchParams(router.asPath.split(/\?|#/)[1]);
  const query = q.get('q') || '';
  const tab = q.get('tab') || 'obligation';
  const [value, setValue] = useState<AutocompleteValue>({
    tab: '',
    label: '',
    value: '',
  });
  const [searchQuery, setSearchQuery] = useState<string>(query);
  const recentSearch = getRecentSearchFromStorage();

  useEffect(() => {
    const { tab, value: val, label } = value as AutocompleteItemProp;
    if (val && tab && label) {
      router.push(urlWithSlug(`/search?q=${label}&tab=${tab}`));
      setTimeout(() => {
        setIsFocus(false);
        ref.current?.blur();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (query && pathname.includes('/search')) {
      setValue({ label: query, value: query, tab: tab });
    } else {
      setValue({ label: '', value: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query]);

  return (
    <div className="flex gap-x-2 items-center">
      {isFocus && (
        <CaretCircleRight
          className="text-primary-400 cursor-pointer"
          weight="fill"
          size={24}
          onClick={() => {
            ref?.current?.blur();
            setIsFocus(false);
          }}
        />
      )}
      <div>
        <Autocomplete
          defaultQuery={query}
          openOnFocus
          onQueryChange={(query) => {
            setSearchQuery(query);
          }}
          onFocus={() => setIsFocus(true)}
          ref={ref}
          className={classnames(className, 'mr-4 h-[40px] flex-grow')}
          placeholder={t('search')}
          value={value}
          onChange={setValue}
          suffix={<MagnifyingGlass size={16} />}
          onSuffixClicked={() => {
            setIsFocus(true);
            (ref?.current?.children[0]?.children[1] as HTMLInputElement) // get the input in autocompolete
              ?.focus();
          }}
          suffixClass="bg-neutral-75 !text-neutral-400 flex justify-center w-[40px] !p-0 cursor-pointer search-suffix"
          customValueRenderer={(value: string) => {
            return value;
          }}
          clearQueryOnClose={false}
          displayValue={({ query, item }) => {
            return Object.keys(searchTabAndLabel).includes(
              String((item as AutocompleteItemProp)?.value)
            )
              ? query
              : String((item as AutocompleteItemProp)?.label ?? '');
          }}
          prependItem={(query: string) => {
            return query
              ? Object.keys(searchTabAndLabel).map((key) => (
                  <AutocompleteItem
                    key={key}
                    value={{
                      label: query,
                      value: key,
                      query,
                      tab: key,
                    }}
                  >
                    {({ active }) => (
                      <>
                        <span className="truncate">{query}</span>
                        <AutocompleteLabel active={active}>
                          in {searchTabAndLabel[key as SearchTab]}
                        </AutocompleteLabel>
                      </>
                    )}
                  </AutocompleteItem>
                ))
              : recentSearch.length > 0 && (
                  <>
                    <div className="flex gap-1 py-[6px] px-2 bg-neutral-50">
                      <ClockCounterClockwise size={18} />
                      <TextDS2
                        agDesktop="Desktop/Caption/Medium"
                        agMobile="Desktop/Caption/Medium"
                        color="Neutral/300"
                      >
                        {t('common.recentSearch')}
                      </TextDS2>
                    </div>
                    {recentSearch.map((item, index) => (
                      <AutocompleteItem
                        key={index}
                        value={{
                          ...item,
                          query: item.label,
                        }}
                      >
                        {({ active }) => (
                          <>
                            <span className="truncate">{item.label}</span>
                            <AutocompleteLabel active={active}>
                              in {searchTabAndLabel[item.tab as SearchTab]}
                            </AutocompleteLabel>
                          </>
                        )}
                      </AutocompleteItem>
                    ))}
                  </>
                );
          }}
          inputClass={({ query, item }) => {
            return query &&
              Object.keys(searchTabAndLabel).includes(
                String((item as AutocompleteItemProp)?.value)
              )
              ? `!flex-grow-0 input-search-header-nav ${isFocus ? 'focused' : 'not-focused'}`
              : `input-search-header-nav ${isFocus ? 'focused' : 'not-focused'}`;
          }}
          inputStyle={({ query }) => {
            return {
              width: query.length * 8 + 20,
              paddingRight: 2,
              maxWidth: query.length > 90 ? 90 * 8 : '100%',
              minWidth: 'calc(100% - 40px)',
            };
          }}
          selectionRenderer={({ item }) => {
            return item ? <div className="flex-1"></div> : null;
          }}
          hideEmptyResult
          optionsClass="empty:hidden"
          clearable
          {...props}
        />
      </div>
    </div>
  );
}

export default Search;
