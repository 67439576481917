import { Menu, MenuItem } from '@hol-jsp/dashboard-dsl';
import { SideNavNavigationProps } from './SideNav';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { getOrgSettingsFromCookies } from 'helper';
import { useActionTracker } from '@/helper/actionTracker';
import { useActivityLog } from '@/modules/general/hooks';

const ExpandedMenus = ({ nav }: { nav: SideNavNavigationProps[] }) => {
  const router = useRouter();
  const pathname = router.asPath;
  const orgSettings = getOrgSettingsFromCookies();
  const { mutate } = useActivityLog();
  const isActiveOnChild = useCallback(
    (childNav: SideNavNavigationProps['childNav']) => {
      return childNav?.some(
        (item) => item.href === pathname || pathname.includes(item.href)
      );
    },
    [pathname]
  );

  const useTrackedAction = (mutate: any) => {
    return useCallback(
      (params: any) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useActionTracker({
          ...params,
          mutate,
        });
      },
      [mutate]
    );
  };

  const trackAction = useTrackedAction(mutate);

  const handleClickMenu = (data: any) => {
    trackAction({
      action: 'click-side-navigation',
      type: 'side-navigation',
      category: 'Side Navigation',
      status: 'success',
      data,
    });
  };

  return (
    <Menu tw="font-nunito-sans">
      {nav
        ? nav.map((data, idx) => (
            <div key={idx}>
              {data.childNav ? (
                <>
                  <Menu
                    active={isActiveOnChild(data.childNav)}
                    style={{
                      backgroundColor: isActiveOnChild(data.childNav)
                        ? orgSettings?.sidenav_active_menu_background_color
                        : '',
                      color: isActiveOnChild(data.childNav)
                        ? orgSettings?.sidenav_active_text_and_icon_color
                        : orgSettings?.sidenav_text_and_icon_color,
                    }}
                  >
                    <div onClick={() => handleClickMenu(data)}>
                      <MenuItem
                        as={Link}
                        icon={data.icon}
                        active={data.active}
                        href={data.href}
                        child={false}
                        data-testid={data.testid}
                        label={data.label}
                        style={{
                          backgroundColor: data.active
                            ? orgSettings?.sidenav_active_menu_background_color
                            : '',
                          color: data.active
                            ? orgSettings?.sidenav_active_text_and_icon_color
                            : orgSettings?.sidenav_text_and_icon_color,
                        }}
                        className="forceStyle"
                      />
                    </div>
                    {data.childNav.map((dataChild, index) => (
                      <div
                        onClick={() => handleClickMenu(dataChild)}
                        key={index}
                      >
                        <MenuItem
                          as={Link}
                          icon={dataChild.icon}
                          active={dataChild.active}
                          href={dataChild.href}
                          data-testid={data.testid}
                          label={dataChild.label}
                          child
                          style={{
                            backgroundColor: dataChild.active
                              ? orgSettings?.sidenav_active_menu_background_color
                              : '',
                            color: dataChild.active
                              ? orgSettings?.sidenav_active_text_and_icon_color
                              : orgSettings?.sidenav_text_and_icon_color,
                          }}
                          className="forceStyle"
                        />
                      </div>
                    ))}
                  </Menu>
                </>
              ) : (
                <div onClick={() => handleClickMenu(data)}>
                  <MenuItem
                    as={Link}
                    icon={data.icon}
                    active={data.active}
                    href={data.href}
                    child={false}
                    data-testid={data.testid}
                    label={data.label}
                    style={{
                      backgroundColor: data.active
                        ? orgSettings?.sidenav_active_menu_background_color
                        : '',
                      color: data.active
                        ? orgSettings?.sidenav_active_text_and_icon_color
                        : orgSettings?.sidenav_text_and_icon_color,
                    }}
                    className="forceStyle"
                  />
                </div>
              )}
            </div>
          ))
        : null}
    </Menu>
  );
};

export default ExpandedMenus;
