import ObligationStatus, { ObligationStatusColor } from './obligation-status';

export default function ObligationBadges({
  status,
  isNew,
  isActivated,
  version,
  hideVersion,
}: {
  status?: string;
  isNew?: boolean;
  isActivated?: boolean;
  version?: number; // obligation_version
  hideVersion?: boolean;
}) {
  const statusColors: Record<string, ObligationStatusColor> = {
    Amended: 'warning',
    Repealed: 'danger',
  };
  return (
    <>
      {status && status !== 'In Effect' && (
        <ObligationStatus color={statusColors[status]}>
          {status}
        </ObligationStatus>
      )}
      {isNew && <ObligationStatus color="success">New</ObligationStatus>}
      {isActivated && <ObligationStatus color="info">Active</ObligationStatus>}
      {version && !hideVersion && (
        <ObligationStatus color="warning">Version {version}</ObligationStatus>
      )}
    </>
  );
}
