import Link from 'next/link';

import { useTranslation } from 'react-i18next';

import { BubbleBox, Text, TextDS2 } from '@hol-jsp/dashboard-dsl';

import MarkAllAsReadBtn from 'page-components/notification/MarkAllAsReadBtn';
import CardNotification from 'page-components/notification/CardNotification';
import { INotification, IPagination } from 'types/notification';
import { Meta } from 'types/general';

import { LoadingImage } from './Layout';
import { EmptyState } from '../Common';

import 'twin.macro';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useReadAllNotification,
  useReadNotificationById,
} from 'modules/notification/hooks';
import { urlWithSlug } from 'helper';
import { useRouter } from 'next/router';
import { useActionTracker } from '@/helper/actionTracker';
import { useActivityLog } from '@/modules/general/hooks';
import { useCallback } from 'react';

interface INotificationBubble {
  onClick: VoidFunction;
  isLoading: boolean;
  data?: INotification[];
  pagination: IPagination;
  meta?: Meta;
  getNext: VoidFunction;
  refetchNotificationList: () => void;
  handleShowObligation: (
    compliance_id: string,
    title: string,
    created_at: string,
    type: string
  ) => void;
  trackAction?: ({ action, type, category, status, data }: any) => void;
}

const NotificationBubble = (props: INotificationBubble) => {
  const { onClick } = props;
  const { mutate } = useActivityLog();

  const useTrackedAction = (mutate: any) => {
    return useCallback(
      (params: any) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useActionTracker({
          ...params,
          mutate,
        });
      },
      [mutate]
    );
  };

  const trackAction = useTrackedAction(mutate);

  return (
    <BubbleBox showHandler={onClick}>
      <div tw="absolute top-full right-[82.44px] mt-5 mr-12 -translate-y-3 z-10 shadow-[2px_8px_13px_0_#00336612]">
        <div tw="bg-white w-[600px] text-black rounded border-[1px] border-neutral-200 max-h-[88vh]">
          <div tw="flex flex-col max-h-[calc(88vh-2px)]">
            <Header onClick={onClick} trackAction={trackAction} />
            <Content trackAction={trackAction} {...props} />
          </div>
        </div>
      </div>
    </BubbleBox>
  );
};

const Header = ({
  onClick,
  trackAction,
}: {
  onClick: VoidFunction;
  trackAction: ({ action, type, category, status, data }: any) => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const company = router.query.company;

  const handleClickShowAllNotification = () => {
    trackAction({
      action: 'click-show-all-notification',
      type: 'notification',
      category: 'Notification',
      status: 'success',
      data: {},
    });
    onClick();
  };

  return (
    <div className="p-4 mb-1 flex flex-row justify-between">
      <div>
        <Text as="body-1" tw="font-inter font-bold">
          {t('notification.notification')}
        </Text>
        <TextDS2
          agDesktop="Desktop/Body 3/Medium"
          agMobile="Desktop/Body 3/Medium"
          className="mt-1"
          color="Neutral/300"
        >
          {t('notification.showingLatestNotification')}
        </TextDS2>
      </div>

      <div>
        <Link
          href={`/${company}/notification`}
          className="inline"
          onClick={handleClickShowAllNotification}
        >
          <TextDS2
            agDesktop="Desktop/Caption/Medium"
            agMobile="Desktop/Caption/Medium"
            color="Primary/Main"
          >
            {t('notification.showAllNotification')}
          </TextDS2>
        </Link>
      </div>
    </div>
  );
};

const Content = ({
  data,
  meta,
  getNext,
  refetchNotificationList,
  handleShowObligation,
  trackAction,
}: INotificationBubble) => {
  const { t } = useTranslation();

  const { mutate } = useReadAllNotification();
  const { mutate: readNotificationByid } = useReadNotificationById();

  const handleMarkAllAsRead = async () => {
    mutate();
    refetchNotificationList();
    trackAction?.({
      action: 'click-mark-all-as-read',
      type: 'notification',
      category: 'Notification',
      status: 'success',
      data: {},
    });
  };

  if (data?.length === 0) {
    return (
      <EmptyState
        message="Not Found"
        description="You don't have notification"
      />
    );
  }

  const handleClickDetail = async (dataDetail: any) => {
    if (
      dataDetail?.type === 'obligation' &&
      dataDetail?.action === 'update-content'
    ) {
      return;
    } else {
      if (dataDetail.read_at === '') {
        await readNotificationByid(dataDetail.id, {
          onSuccess: () => {
            trackAction?.({
              action: 'click-read-notification',
              type: 'notification',
              category: 'Notification',
              status: 'success',
              data: { dataDetail },
            });
            refetchNotificationList();
          },
        });
      }

      const openUrl = (path: string) => {
        const url = `${window.location.origin}${urlWithSlug(path)}`;
        window.open(url, '_blank');
        trackAction?.({
          action: 'click-notification',
          type: 'notification',
          category: 'Notification',
          status: 'success',
          data: {
            url,
            dataDetail,
          },
        });
      };

      switch (dataDetail?.type) {
        case 'role-compliance':
          openUrl(
            `/company-management/role-compliance/${dataDetail?.data?.role_id}`
          );
          break;
        case 'role':
          openUrl(`/company-management/privilege/${dataDetail?.data?.role_id}`);
          break;
        case 'group':
          if (['delete', 'attach'].includes(dataDetail?.action)) {
            openUrl('/company-management/user-group');
          } else {
            openUrl(
              `/company-management/user-group/${dataDetail?.data?.group_id}`
            );
          }
          break;
        case 'compliance':
          if (
            ['remove-assignee', 'delete-checklist'].includes(dataDetail?.action)
          ) {
            openUrl('/compliance-monitor');
          } else {
            openUrl(`/compliance-monitor/${dataDetail?.data?.compliance_id}`);
          }
          break;
        case 'obligation':
          if (dataDetail?.action === 'share') {
            openUrl(
              `/sector/${dataDetail?.data?.sector_id}?topic_id=${dataDetail?.data?.topic_id}&obligation_id=${dataDetail?.data?.obligation_id}`
            );
            break;
          } else {
            openUrl(`/obligation-updates/${dataDetail?.data?.obligation_id}`);
            break;
          }
        case 'reminder-obligation':
          openUrl(`/obligation-updates/${dataDetail?.data?.obligation_id}`);
          break;
        case 'regulation':
          openUrl(
            `/regulation/${dataDetail?.data?.hierarchy_id || '368d9b81-d245-11ee-be87-0a0f2ec25530'}/${dataDetail?.data?.regulation_id}`
          );
          break;
        case 'document':
          openUrl(`/compliance-attachment/${dataDetail?.data?.document_id}`);
          break;
        case 'comment':
          openUrl(`/compliance-monitor/${dataDetail?.data?.compliance_id}`);
          break;
      }
    }
  };

  return (
    <>
      <div tw="flex justify-between mb-4 px-4">
        <TextDS2
          agDesktop="Desktop/Body 2/Semi Bold"
          agMobile="Desktop/Body 2/Semi Bold"
          color="Neutral/500"
        >
          {t('notification.latest')}
        </TextDS2>

        <MarkAllAsReadBtn size="sm" onClick={handleMarkAllAsRead} />
      </div>
      <div className="overflow-y-auto px-4" id="scrollable-notification">
        <InfiniteScroll
          scrollableTarget="scrollable-notification"
          dataLength={data?.length || 0}
          next={getNext}
          hasMore={(meta?.limit || 0) < (meta?.total_records || 0)}
          loader={
            <div className="flex justify-center mb-4">
              <LoadingImage />
            </div>
          }
        >
          {data?.map((item, index) => (
            <div
              key={index}
              className={index === (data?.length || 1) - 1 ? 'mb-4' : undefined}
              onClick={() => handleClickDetail(item)}
            >
              <CardNotification
                data={item}
                size="sm"
                handleShowObligation={handleShowObligation}
                trackAction={trackAction}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default NotificationBubble;
