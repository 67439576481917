import 'twin.macro';
import {
  Autocomplete,
  AutocompleteItemProp,
  AutocompleteValue,
  Checkbox,
  TextDS2,
  DatePickerReminderValue,
  DatePickerWithReminder,
} from '@hol-jsp/dashboard-dsl';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass } from 'phosphor-react';
import dayjs from 'dayjs';
import FilterCard from '@/components/Card/FilterCard';
import FilterCardFooter from '@/components/Card/FilterCardFooter';
import { ThemeButton } from '@/components/ThemeButton';
import SearchFilter, { CustomRef } from '@/components/search_filter';
import { useTopicList } from '@/modules/regulation-detail/hooks';
import {
  IComplianceFilterOptions,
  IFilterUserGroupAssignee,
  IFilterAssignee,
  IFilterUserGroupVerificator,
  IFilterVerificator,
  IFilterStatus,
} from '@/types/compliance-monitor';
import { IFilterItems, DataItem } from '@/types/general';
import {
  ObligationOptionType1,
  ObligationOptionType2,
  Hierarchy,
  HiearchyRegulation,
  RegulationOption,
} from '@/types/regulation-detail';
import { SectorTopic } from '@/types/sector-topic';

export const RegulationFilterName = 'regulations';
export const SectorFilterName = 'sector_id';
export const TopicFilterName = 'topics';
export const ObligationFilterName = 'obligation';
export const AssigneeGroupFilterName = 'assigneeGroups';
export const AssigneeFilterName = 'assignees';
export const VerificatorGroupFilterName = 'verificatorGroups';
export const VerificatorFilterName = 'verificators';
export const DueDateFilterName = 'dueDate';
export const ExpiredDateFilterName = 'expiredDate';

export default function Filter({
  show,
  filterItems,
  onSubmit,
  setShow,
  isOnSearchPage = false,
  isOnBookmarkPage = false,
  filterOptions,
}: {
  show: boolean;
  filterItems: IFilterItems[];
  onSubmit: (data: IFilterItems[]) => void;
  setShow: (data: boolean) => void;
  isOnSearchPage?: boolean;
  isOnBookmarkPage?: boolean;
  filterOptions?: IComplianceFilterOptions;
}) {
  const assigneeGroupRef = useRef<CustomRef>(null);
  const { t } = useTranslation();

  const [items, setItems] = useState<IFilterItems[]>([]);
  const [reset, setReset] = useState<boolean>(false);
  const [selectedSector, setSelectedSector] = useState<AutocompleteValue>();
  const [selectedObligation, setSelectedObligation] =
    useState<AutocompleteValue>();
  const [selectedDueDate, setDueDate] = useState<DatePickerReminderValue>();
  const [selectedExpiredDate, setExpiredDate] =
    useState<DatePickerReminderValue>();

  const [sectorQuery, setSectorQuery] = useState<string>('');
  const [updateDataTopics, setUpdateDataTopics] = useState<boolean>(false);

  function isObligationType(type: string) {
    return ['obligation_type', 'obligation_penalty'].includes(type);
  }

  const onChange = (
    item:
      | ObligationOptionType1
      | ObligationOptionType2
      | IComplianceFilterOptions,
    type:
      | 'applicability_status'
      | 'version_history'
      | 'compliance_status'
      | 'compliance_progress'
      | 'obligation_type'
      | 'obligation_penalty',
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    function findItem(
      filterItem: (typeof items)[0],
      item:
        | ObligationOptionType1
        | ObligationOptionType2
        | IComplianceFilterOptions
    ) {
      return isObligationType(type)
        ? filterItem.value === (item as ObligationOptionType2).id
        : filterItem.value === (item as ObligationOptionType1).code;
    }

    if (isChecked) {
      setItems([
        ...items,
        {
          name: type,
          value: isObligationType(type)
            ? (item as ObligationOptionType2).id
            : (item as ObligationOptionType1).code,
          label: (item as ObligationOptionType2).name,
        },
      ]);
    } else {
      setItems(items.filter((filterItem) => !findItem(filterItem, item)));
    }
  };

  const onClearFilter = () => {
    setItems([]);
    setSelectedSector(null);
    setSelectedObligation(null);
    resetToLastData();
    setDueDate(undefined);
    setExpiredDate(undefined);
  };

  const resetToLastData = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 500);
  };

  const { data: topicData, refetch: refetchTopicList } = useTopicList(
    (selectedSector as AutocompleteItemProp)?.value as string,
    {
      language_id: 'id',
      page: 1,
      limit: -1,
    }
  );

  useEffect(() => {
    setUpdateDataTopics(true);
    setTimeout(() => {
      setUpdateDataTopics(false);
    }, 300);
  }, [topicData]);

  useEffect(() => {
    refetchTopicList();
  }, [refetchTopicList, selectedSector]);

  const predefinedFiltersFirst = [
    'compliance_status',
    'compliance_progress',
    'compliance_priority',
  ] as const;

  const predefinedFiltersSecond = [
    'obligation_type',
    'obligation_penalty',
  ] as const;

  useEffect(() => {
    if (show) {
      setItems(filterItems);
      resetToLastData();
    } else if (filterItems.length < 1 || items.length < 1) {
      onClearFilter();
    }
  }, [show, filterItems]);

  const [updateData, setUpdateData] = useState<boolean>(false);

  const mapFilterOptionData = ({
    data,
    level = 1,
    name,
  }: {
    data:
      | IFilterUserGroupAssignee
      | IFilterAssignee
      | IFilterUserGroupVerificator
      | IFilterVerificator;
    level?: number;
    name: string;
  }): any => {
    return {
      id: data.id,
      label: data.name,
      level: level,
      collapsed: false,
      checked: items.some(
        (item) => item.name === name && item.value === data.id
      ),
      children: [],
    };
  };

  const mapTreeOptionData = ({
    data,
    level = 1,
  }: {
    data: Hierarchy | HiearchyRegulation | SectorTopic;
    level?: number;
  }): any => {
    return {
      id: data.id,
      label: (data as Hierarchy).name || (data as HiearchyRegulation).title,
      level: level,
      collapsed: true,
      checked: items.some((item) => item.value === data.id),
      children: (data as Hierarchy)?.regulations
        ? (data as Hierarchy)?.regulations.map((item) =>
            mapTreeOptionData({ data: item, level: level + 1 })
          )
        : (data as SectorTopic)?.topics
          ? (data as SectorTopic)?.topics.map((item) =>
              mapTreeOptionData({ data: item, level: level + 1 })
            )
          : [],
    };
  };

  const topicOptions = useMemo(() => {
    if (topicData && topicData?.data?.data) {
      return topicData?.data?.data.map((topic) =>
        mapTreeOptionData({ data: topic })
      );
    }
    return [];
  }, [topicData, items]);

  const regulationOptions = useMemo<RegulationOption[]>(() => {
    if (filterOptions && filterOptions?.hierarchies) {
      return filterOptions.hierarchies.map((hierarchy) =>
        mapTreeOptionData({ data: hierarchy })
      );
    }
    return [];
  }, [filterOptions, items]);

  const obligationOptions = useMemo<AutocompleteItemProp[]>(() => {
    if (filterOptions && filterOptions?.obligation) {
      return filterOptions.obligation.map((val) => ({
        label: val.name,
        value: val.id,
      }));
    }
    return [];
  }, [filterOptions, items]);

  const assigneeGroupOptions = useMemo<
    DataItem<IFilterUserGroupAssignee>[]
  >(() => {
    if (filterOptions && filterOptions?.user_group_assignee) {
      return filterOptions.user_group_assignee.map((val) =>
        mapFilterOptionData({ data: val, name: AssigneeGroupFilterName })
      );
    }
    return [];
  }, [filterOptions, items]);

  const assigneeOptions = useMemo<DataItem<IFilterAssignee>[]>(() => {
    if (filterOptions && filterOptions?.assignee) {
      return filterOptions?.assignee.map((val) =>
        mapFilterOptionData({ data: val, name: AssigneeFilterName })
      );
    }
    return [];
  }, [filterOptions, items]);

  const verificatorGroupOptions = useMemo<
    DataItem<IFilterUserGroupVerificator>[]
  >(() => {
    if (filterOptions && filterOptions?.user_group_verificator) {
      return filterOptions?.user_group_verificator.map((val) =>
        mapFilterOptionData({ data: val, name: VerificatorGroupFilterName })
      );
    }
    return [];
  }, [filterOptions, items]);

  const verificatorOptions = useMemo<DataItem<IFilterVerificator>[]>(() => {
    if (filterOptions && filterOptions?.verificator) {
      return filterOptions?.verificator.map((val) =>
        mapFilterOptionData({ data: val, name: VerificatorFilterName })
      );
    }
    return [];
  }, [filterOptions, items]);

  useEffect(() => {
    const filterByObligation = filterItems.find(
      (item) => item.name === ObligationFilterName
    );
    if (filterByObligation) {
      setSelectedObligation({
        label: filterByObligation.label,
        value: filterByObligation.value,
      });
    } else {
      setSelectedObligation(undefined);
    }

    const filterBySector = filterItems.find(
      (item) => item.name === SectorFilterName
    );
    if (filterBySector) {
      setSelectedSector({
        label: filterBySector.label,
        value: filterBySector.value,
      });
    } else {
      setSelectedSector(undefined);
    }
  }, [filterItems]);

  if (!show) return null;
  return (
    <FilterCard>
      <div className="self-stretch justify-between items-start inline-flex">
        <TextDS2
          agDesktop="Desktop/Body 2/Semi Bold"
          agMobile="Desktop/Body 2/Semi Bold"
          color="Neutral/400"
        >
          {t('general.filter')}
        </TextDS2>
        <ThemeButton
          variant="text"
          size="sm"
          className="!p-0 font-sans"
          onClick={() => setShow(false)}
        >
          {t('general.close')}
        </ThemeButton>
      </div>

      <div>
        <TextDS2
          agDesktop="Desktop/Body 3/Medium"
          agMobile="Desktop/Body 3/Medium"
          color="Neutral/400"
          className="mb-2"
        >
          {t('regulation.regulation')}
        </TextDS2>
        <SearchFilter
          data={regulationOptions}
          onChange={(checked) => {
            const itemWithoutRegulations = items.filter(
              (item) => item.name !== RegulationFilterName
            );
            const newRegulationItems = checked.map((item) => ({
              name: RegulationFilterName,
              value: item.id,
              label: item.label,
            }));
            const newItems = [...itemWithoutRegulations, ...newRegulationItems];
            setItems(newItems);
          }}
          reset={reset}
          updateData={updateData}
          flip
          autoPlacement={false}
          placeholder={t('regulation.searchRegulation')}
        />
      </div>

      <div>
        <TextDS2
          agDesktop="Desktop/Body 3/Medium"
          agMobile="Desktop/Body 3/Medium"
          color="Neutral/400"
        >
          {t('sector')}
        </TextDS2>
        <Autocomplete
          value={selectedSector!}
          onChange={(val) => {
            const itemWithoutSector = items.filter(
              (item) => item.name !== SectorFilterName
            );
            const newItems = [
              ...itemWithoutSector,
              {
                name: SectorFilterName,
                value: String((val as AutocompleteItemProp).value),
                label: (val as AutocompleteItemProp).label,
              },
            ];
            setItems(newItems);
            setSelectedSector(val);
          }}
          withTrigger
          prefix={<MagnifyingGlass size={20} />}
          items={
            filterOptions?.sector?.map((item) => ({
              label: item.name,
              value: item.id,
            })) || []
          }
          placeholder={t('filterSectorPlaceholder')}
          onQueryChange={setSectorQuery}
        />
      </div>

      <div>
        <TextDS2
          agDesktop="Desktop/Body 3/Medium"
          agMobile="Desktop/Body 3/Medium"
          color="Neutral/400"
        >
          {t('obligation.topicLevel')}
        </TextDS2>
        <SearchFilter
          ref={assigneeGroupRef}
          data={topicOptions}
          onChange={(checked) => {
            const itemWithoutGroups = items.filter(
              (item) => item.name !== TopicFilterName
            );
            const newGroupItems = checked.map((item) => ({
              name: TopicFilterName,
              value: item.id,
              label: item.label,
            }));
            const newItems = [...itemWithoutGroups, ...newGroupItems];
            setItems(newItems);
          }}
          reset={reset}
          updateData={updateDataTopics}
          disabled={!selectedSector}
          flip
          placeholder={t('compliance.filter.searchTopic')}
        />
      </div>

      <div>
        <TextDS2
          agDesktop="Desktop/Body 3/Medium"
          agMobile="Desktop/Body 3/Medium"
          color="Neutral/400"
        >
          {t('compliance.filter.obligation')}
        </TextDS2>
        <Autocomplete
          value={selectedObligation!}
          onChange={(val) => {
            const prevFilteredItems = items.filter(
              (item) => item.name !== ObligationFilterName
            );
            const newAssigneeGroupItems = [
              {
                name: ObligationFilterName,
                value: (val as AutocompleteItemProp)?.value as string,
                label: (val as AutocompleteItemProp)?.label,
              },
            ];
            const newItems = [...prevFilteredItems, ...newAssigneeGroupItems];
            setItems(newItems);
            setSelectedObligation(val);
          }}
          withTrigger
          prefix={<MagnifyingGlass size={20} />}
          items={obligationOptions}
          placeholder={t('compliance.filter.searchObligation')}
          onQueryChange={setSectorQuery}
          disabled={!selectedSector}
          itemClass="h-max"
        />
      </div>

      {predefinedFiltersFirst.map((keyword) => (
        <>
          <CheckboxFilter
            data={filterOptions}
            isObligationType={isObligationType}
            items={items}
            onChange={onChange}
            keyword={keyword}
          />
        </>
      ))}

      <div>
        <TextDS2
          color="Neutral/400"
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          tw="mb-1"
        >
          {t('compliance.filter.assignee_group')}
        </TextDS2>
        <SearchFilter
          data={assigneeGroupOptions}
          onChange={(checked) => {
            const prevFilteredItems = items.filter(
              (item) => item.name !== AssigneeGroupFilterName
            );
            const newAssigneeGroupItems = checked.map((item) => ({
              name: AssigneeGroupFilterName,
              value: item.id,
              label: item.label,
            }));
            const newItems = [...prevFilteredItems, ...newAssigneeGroupItems];
            setItems(newItems);
          }}
          reset={reset}
          updateData={false}
          placeholder={t('compliance.filter.searchAssigneeGroup')}
        />
      </div>

      <div>
        <TextDS2
          color="Neutral/400"
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          tw="mb-1"
        >
          {t('compliance.filter.assignee')}
        </TextDS2>
        <SearchFilter
          data={assigneeOptions}
          onChange={(checked) => {
            const prevFilteredItems = items.filter(
              (item) => item.name !== AssigneeFilterName
            );
            const newAssigneeItems = checked.map((item) => ({
              name: AssigneeFilterName,
              value: item.id,
              label: item.label,
            }));
            const newItems = [...prevFilteredItems, ...newAssigneeItems];
            setItems(newItems);
          }}
          reset={reset}
          updateData={false}
          placeholder={t('compliance.filter.searchAssignee')}
        />
      </div>

      <div>
        <TextDS2
          color="Neutral/400"
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          tw="mb-1"
        >
          {t('compliance.filter.verificator_group')}
        </TextDS2>
        <SearchFilter
          data={verificatorGroupOptions}
          onChange={(checked) => {
            const prevFilteredItems = items.filter(
              (item) => item.name !== VerificatorGroupFilterName
            );
            const newUserItems = checked.map((item) => ({
              name: VerificatorGroupFilterName,
              value: item.id,
              label: item.label,
            }));
            const newItems = [...prevFilteredItems, ...newUserItems];
            setItems(newItems);
          }}
          reset={reset}
          updateData={false}
          placeholder={t('compliance.filter.searchVerificatorGroup')}
        />
      </div>

      <div>
        <TextDS2
          color="Neutral/400"
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          tw="mb-1"
        >
          {t('compliance.filter.verificator')}
        </TextDS2>
        <SearchFilter
          data={verificatorOptions}
          onChange={(checked) => {
            const prevFilteredItems = items.filter(
              (item) => item.name !== VerificatorFilterName
            );
            const newVerificatorItems = checked.map((item) => ({
              name: VerificatorFilterName,
              value: item.id,
              label: item.label,
            }));
            const newItems = [...prevFilteredItems, ...newVerificatorItems];
            setItems(newItems);
          }}
          reset={reset}
          updateData={false}
          placeholder={t('compliance.filter.searchVerificator')}
        />
      </div>

      {predefinedFiltersSecond.map((keyword) => (
        <>
          <CheckboxFilter
            data={filterOptions}
            isObligationType={isObligationType}
            items={items}
            onChange={onChange}
            keyword={keyword}
          />
        </>
      ))}

      <div>
        <TextDS2
          color="Neutral/400"
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          tw="mb-1"
        >
          {t('compliance.filter.due_date')}
        </TextDS2>
        <DatePickerWithReminder
          value={selectedDueDate}
          onValueChange={(value) => {
            setDueDate(value);
            const prevFilteredItems = items.filter(
              (item) => item.name !== DueDateFilterName
            );
            const newItems = [
              ...prevFilteredItems,
              {
                name: DueDateFilterName,
                label: dayjs(value.date as Date).format('DD MMMM YYYY, HH:mm'),
                value: (value.date as Date).toISOString(),
              },
            ];
            setItems(newItems);
          }}
          showButtonAddReminder={false}
          flexColumn
        />
      </div>

      <div>
        <TextDS2
          color="Neutral/400"
          agMobile="Desktop/Body 3/Medium"
          agDesktop="Desktop/Body 3/Medium"
          tw="mb-1"
        >
          {t('compliance.filter.expired_date')}
        </TextDS2>
        <DatePickerWithReminder
          value={selectedExpiredDate}
          onValueChange={(value) => {
            setExpiredDate(value);
            const prevFilteredItems = items.filter(
              (item) => item.name !== ExpiredDateFilterName
            );
            const newItems = [
              ...prevFilteredItems,
              {
                name: ExpiredDateFilterName,
                label: dayjs(value.date as Date).format('DD MMMM YYYY, HH:mm'),
                value: (value.date as Date).toISOString(),
              },
            ];
            setItems(newItems);
          }}
          showButtonAddReminder={false}
        />
      </div>

      <FilterCardFooter>
        <ThemeButton
          variant="text"
          size="sm"
          className="!py-2 h-10 font-sans"
          onClick={onClearFilter}
        >
          {t('filter.clearAll')}
        </ThemeButton>
        <ThemeButton
          size="sm"
          className="!px-4 h-10 font-sans"
          onClick={() => onSubmit(items)}
        >
          {t('general.apply')}
        </ThemeButton>
      </FilterCardFooter>
    </FilterCard>
  );
}

function joinAndFilterDuplicatedCodeForComplianceStatusOption(
  item: IFilterStatus[]
) {
  const reducedData: Record<string, string[]> = {};
  item.forEach((status: IFilterStatus) => {
    if (!reducedData[status.name]) {
      reducedData[status.name] = [];
    }
    reducedData[status.name] = Array.from(
      new Set([...reducedData[status.name], status.code])
    );
  });

  Object.entries(reducedData).forEach(([name, codes]) => {
    const combinedCodes = codes.join(',');
    item.forEach((status) => {
      if (status.name === name) {
        status.code = combinedCodes;
      }
    });
  });

  const uniqueCodes = new Set();

  const filteredStatus = item.filter((status) => {
    if (!uniqueCodes.has(status.code)) {
      uniqueCodes.add(status.code);
      return true;
    }
    return false;
  });

  return filteredStatus;
}

function CheckboxFilter({
  keyword,
  data,
  isObligationType,
  onChange,
  items,
}: {
  keyword: string;
  data?: IComplianceFilterOptions;
  isObligationType: (key: string) => boolean;
  onChange: (
    item: // | ObligationOptionType1
    // | ObligationOptionType2
    IComplianceFilterOptions,
    type:
      | 'applicability_status'
      | 'version_history'
      | 'compliance_status'
      | 'obligation_type'
      | 'obligation_penalty',
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  items: IFilterItems[];
}) {
  const { t } = useTranslation();

  const checkboxData = useMemo(() => {
    if (data && data[keyword]) {
      // case for: same status with different progress (assigned and ready to review)
      // have different code, so we join it to filter both
      if (keyword === 'compliance_status') {
        return joinAndFilterDuplicatedCodeForComplianceStatusOption(
          data[keyword]
        );
      }

      return data[keyword];
    }
  }, [data, keyword]);

  return (
    <>
      <TextDS2
        agDesktop="Desktop/Body 3/Medium"
        agMobile="Desktop/Body 3/Medium"
        color="Neutral/400"
      >
        {t(`compliance.filter.${keyword}`)}
      </TextDS2>
      <div
        className="grid grid-cols-[171.5px_171.5px] gap-2 pb-2"
        key={keyword}
      >
        {checkboxData?.map((item: any) => (
          <Checkbox
            key={isObligationType(keyword) ? item.id : item.code}
            onChange={(e) => onChange(item, keyword as any, e)}
            checked={
              items.filter(
                (filterItem) =>
                  filterItem.value ===
                  (isObligationType(keyword) ? item.id : item.code)
              ).length > 0
            }
          >
            <TextDS2
              agDesktop="Desktop/Caption/Medium"
              agMobile="Desktop/Caption/Medium"
              color="Neutral/400"
            >
              {item.name}
            </TextDS2>
          </Checkbox>
        ))}
        {data && !data[keyword]?.length && (
          <TextDS2
            agDesktop="Desktop/Caption/Medium"
            agMobile="Desktop/Caption/Medium"
            color="Neutral/300"
          >
            {t('general.noData')}
          </TextDS2>
        )}
      </div>
    </>
  );
}
