/* eslint-disable react/no-unstable-nested-components */
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextDS2,
  Button,
  SelectMenu,
  useFloatingAlertInfo,
  Modal,
  TextInput,
  cx,
  Checkbox,
} from '@hol-jsp/dashboard-dsl';
import { CaretDown, Plus, Trash } from 'phosphor-react';
import { ThemeButton } from '../ThemeButton';
import { useSendFeedback } from 'modules/general/hooks';
import { Spinner } from '../Common';
import { AxiosError } from 'axios';
import { useUserInfo } from 'provider/UserProvider';

export interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSuccessSubmit?: () => void;
}

export default function ModalSendFeedback(props: Props) {
  const { isOpen, setIsOpen, onSuccessSubmit } = props;
  const { user } = useUserInfo();
  const { t } = useTranslation();
  const floatingAlertInfo = useFloatingAlertInfo();
  const [type, setType] = useState<'issue' | 'idea'>();
  const [feedback, setFeedback] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [participateSurvey, setParticipateSurvey] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendFeedback = useSendFeedback();

  const onSubmit = () => {
    sendFeedback.mutate(
      {
        attachment: attachments,
        feed_type: type!,
        participate_survey: participateSurvey,
        user_id: String(user?.id),
        feedback,
      },
      {
        onSuccess: () => {
          setSuccess(true);
        },
        onError: (error) => {
          const err = error as AxiosError<{ message: string }>;
          floatingAlertInfo.dispatch({
            message: err?.response?.data?.message || err.message,
            type: 'error',
          });
        },
      }
    );
  };

  const reset = () => {
    setType(undefined);
    setFeedback('');
    setAttachments([]);
    setParticipateSurvey(false);
    setSuccess(false);
  };

  return (
    <div>
      <Modal
        size="none"
        show={isOpen}
        opacity="dark"
        bgColor="main"
        position="center"
        contentClass="!w-[600px]"
        overlayClass="!mt-0"
      >
        <Modal.Body className="p-4 space-y-4">
          <div className="flex gap-2 items-center">
            <img
              src="/images/ic-send-feedback-icon-modal.svg"
              alt="feedback-icon"
              width={36}
              height={36}
            />
            <TextDS2
              color="Neutral/400"
              agMobile="Desktop/Body 2/Semi Bold"
              agDesktop="Desktop/Body 2/Semi Bold"
            >
              {t('sendFeedbackModal.title')}
            </TextDS2>
          </div>
          {!success ? (
            <>
              <TextDS2
                agDesktop="Desktop/Body 3/Regular"
                agMobile="Desktop/Body 3/Regular"
                color="Neutral/400"
              >
                {t('sendFeedbackModal.subtitle')}
              </TextDS2>
              <div>
                <TextDS2
                  color="Neutral/400"
                  agMobile="Desktop/Caption/Medium"
                  agDesktop="Desktop/Caption/Medium"
                  className="mb-1"
                >
                  {t('sendFeedbackModal.feedbackType')}
                </TextDS2>
                <SelectMenu
                  contentClass="min-w-[568.5px]"
                  triggerClass={`form-select w-full h-[50px] border-[1px] text-sm text-neutral-200 font-medium font-inter placeholder:font-medium`}
                  placeholder={t('sendFeedbackModal.selectType')}
                  items={[
                    {
                      label: t('sendFeedbackModal.issue'),
                      value: 'issue',
                    },
                    {
                      label: t('sendFeedbackModal.idea'),
                      value: 'idea',
                    },
                  ]}
                  valueClass={`lowercase !text-[14px] font-normal font-inter overflow-hidden text-neutral-200`}
                  onChange={(val: string) => setType(val as 'issue' | 'idea')}
                  displayValue={({ placeholder }) => (
                    <div
                      className={cx(
                        'text-sm font-normal font-inter',
                        type && 'text-neutral-400'
                      )}
                    >
                      {type ? t(`sendFeedbackModal.${type}`) : placeholder}
                    </div>
                  )}
                  rightIcon={
                    <CaretDown size={20} className="text-neutral-300" />
                  }
                />
              </div>
              {type && (
                <>
                  <div>
                    <TextDS2
                      color="Neutral/400"
                      agMobile="Desktop/Caption/Medium"
                      agDesktop="Desktop/Caption/Medium"
                      className="mb-1"
                    >
                      {t(`sendFeedbackModal.${type}Label`)}
                    </TextDS2>
                    <TextInput
                      value={feedback}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFeedback(e.target.value)
                      }
                      placeholder={''}
                      asTextArea
                    />
                  </div>
                  <div>
                    <TextDS2
                      agDesktop="Desktop/Caption/Medium"
                      agMobile="Desktop/Caption/Medium"
                      color="Neutral/400"
                      className="mb-1"
                    >
                      {t('sendFeedbackModal.addAttachment')}
                    </TextDS2>
                    <ImagePicker
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </div>
                  <Checkbox
                    checked={participateSurvey}
                    onChange={() => setParticipateSurvey(!participateSurvey)}
                  >
                    {t('sendFeedbackModal.participateSurvey')}
                  </Checkbox>
                </>
              )}
              <div className="self-stretch justify-end items-center gap-4 flex">
                <ThemeButton
                  variant="text"
                  size="lg"
                  className="!py-2 h-10 font-sans"
                  onClick={() => setIsOpen(false)}
                >
                  {t('common.cancel')}
                </ThemeButton>
                <ThemeButton
                  size="lg"
                  className="w-[82px] h-10 font-sans"
                  onClick={() => onSubmit()}
                  disabled={!type || !feedback}
                >
                  {t('sendFeedbackModal.send')}
                </ThemeButton>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center">
              <img
                src="/images/send-feedback-success.png"
                alt="send-feedback-success"
                className="my-4"
              />
              <TextDS2
                agDesktop="Desktop/Body 2/Bold"
                agMobile="Desktop/Body 2/Bold"
                color="Neutral/400"
                className="mb-2"
              >
                {t('sendFeedbackModal.success.title')}
              </TextDS2>
              <TextDS2
                agDesktop="Desktop/Body 3/Regular"
                agMobile="Desktop/Body 3/Regular"
                color="Neutral/400"
                className="mb-4"
              >
                {t('sendFeedbackModal.success.subtitle')}
              </TextDS2>
              <div className="self-stretch justify-end items-center gap-4 flex">
                <ThemeButton
                  variant="text"
                  size="lg"
                  className="!py-2 h-10 font-sans"
                  onClick={() => reset()}
                >
                  {t('sendFeedbackModal.success.sendAnotherFeedback')}
                </ThemeButton>
                <ThemeButton
                  size="lg"
                  className="!px-4 h-10 font-sans"
                  onClick={() => setIsOpen(false)}
                  disabled={!type || !feedback || sendFeedback.isLoading}
                >
                  {!sendFeedback.isLoading ? (
                    t('sendFeedbackModal.success.close')
                  ) : (
                    <Spinner />
                  )}
                </ThemeButton>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

function ImagePicker({
  attachments,
  setAttachments,
}: {
  attachments: File[];
  setAttachments: (value: File[]) => void;
}) {
  const floatingAlertInfo = useFloatingAlertInfo();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (
        [...Array.from(files)]
          .map((file) => file.size)
          .some((size) => size > 2e6)
      ) {
        floatingAlertInfo.dispatch({
          message: 'File size cannot exceed 2MB',
          type: 'error',
        });
        return;
      }
      setAttachments([...attachments, ...Array.from(files)]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  return (
    <div className="flex gap-2">
      {attachments.map((attachment, index) => (
        <div
          key={index}
          className="group w-[52px] h-[52px] rounded-[4px] cursor-pointer flex items-center justify-center relative"
          onClick={() => handleRemoveAttachment(index)}
        >
          <img
            alt=""
            className="w-full h-full object-cover group-hover:opacity-50"
            src={URL.createObjectURL(attachment)}
          />
          <Trash
            size={24}
            className="text-danger-500 absolute opacity-0 group-hover:opacity-100"
          />
        </div>
      ))}
      <input
        type="file"
        id="file-input"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />
      <div
        className="w-[52px] h-[52px] bg-neutral-75 flex items-center justify-center rounded-[4px] cursor-pointer"
        onClick={() => {
          const fileInput = document.getElementById(
            'file-input'
          ) as HTMLInputElement;
          fileInput.click();
        }}
      >
        <Plus size={24} className="text-neutral-400" />
      </div>
    </div>
  );
}
