import NotificationStatus from 'page-components/notification/NotificationStatus';
import { dateParser, urlWithSlug } from 'helper';
import { Divider, Modal, TextDS2 } from '@hol-jsp/dashboard-dsl';
import { Monitor } from 'phosphor-react';
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { ThemeButton } from '../ThemeButton';
import { Meta } from 'types/general';
import { LoadingImage } from './Layout';

interface IModalShowMore {
  isShowObligation: boolean;
  setIsShowObligation: Dispatch<SetStateAction<boolean>>;
  data: any;
  title: string;
  createdAt: string;
  handleShowMore: () => void;
  params: Meta;
  setParams: Dispatch<SetStateAction<Meta>>;
  setNotificationDetailData: (data: any) => void;
  isFetching: boolean;
  modalType: string;
  trackAction?: ({ action, type, category, status, data }: any) => void;
}

const ModalShowMore: FC<IModalShowMore> = ({
  isShowObligation,
  setIsShowObligation,
  data,
  title,
  createdAt,
  handleShowMore,
  params,
  setParams,
  setNotificationDetailData,
  isFetching,
  modalType,
  trackAction,
}) => {
  const handleClickDetail = (e: any, dataDetails: any) => {
    e.preventDefault();
    e.stopPropagation();
    let url: string;
    if (modalType === 'compliance') {
      url = `${window.location.origin}${urlWithSlug(`/compliance-monitor/${dataDetails.compliance_id}`)}`;
    } else {
      url = `${window.location.origin}${urlWithSlug(`/sector/${dataDetails.sector_id}?topic_id=${dataDetails.topic_id}&obligation_id=${dataDetails.obligation_id}`)}`;
    }

    window.open(url, '_blank');
    trackAction?.({
      action: 'click-detail-notification',
      type: 'notification',
      category: 'user',
      status: 'success',
      data: dataDetails,
    });
  };

  const handleCloseModal = useCallback(() => {
    setIsShowObligation(false);
    setNotificationDetailData(null);
    setParams({
      limit: 10,
      page: 1,
      total_pages: 1,
      total_records: 0,
    });
    document.body.style.overflow = 'auto';
    trackAction?.({
      action: 'close-modal-obligation-notification',
      type: 'notification',
      category: 'user',
      status: 'success',
      data: {},
    });
  }, [setIsShowObligation, setNotificationDetailData, setParams, trackAction]);

  useEffect(() => {
    if (isShowObligation) {
      document.body.style.overflow = 'hidden';
    }
  }, [isShowObligation]);

  useEffect(() => () => handleCloseModal(), [handleCloseModal]);

  return (
    <div
      className={`fixed inset-0 z-[999] ${isShowObligation ? '' : 'hidden'}`}
    >
      <Modal
        show={isShowObligation}
        size="none"
        contentClass="!w-[800px] bg-white h-[580px] p-4 z-[999]"
      >
        <Modal.Body>
          {isFetching ? (
            <div className="h-[100%] flex items-center justify-center">
              <LoadingImage />
            </div>
          ) : (
            <>
              <header className="flex justify-between items-center">
                <div className="flex gap-4 items-center">
                  <Monitor
                    weight="duotone"
                    size={20}
                    className="text-primary-main"
                  />
                  <TextDS2
                    agDesktop="Desktop/Body 3/Bold"
                    agMobile="Desktop/Body 3/Bold"
                    color="Neutral/400"
                  >
                    {title}
                  </TextDS2>
                </div>
                <TextDS2
                  agDesktop="Desktop/Caption/Semi Bold"
                  agMobile="Desktop/Caption/Semi Bold"
                  color="Neutral/200"
                >
                  {dateParser(createdAt, 'ddd D MMM, HH:mm A')}
                </TextDS2>
              </header>
              <div className="overflow-y-auto px-4 my-4 h-[450px]">
                {data ? (
                  data?.map((item: any, i: number) => (
                    <div
                      key={i}
                      className="transition-colors duration-200 hover:cursor-pointer hover:bg-primary-100"
                      onClick={(e: any) => handleClickDetail(e, item?.data)}
                    >
                      <div className="flex items-center gap-2 mb-2">
                        <TextDS2
                          color="Neutral/300"
                          agDesktop="Desktop/Body 3/Bold"
                          agMobile="Desktop/Body 3/Bold"
                        >
                          {item?.data?.code}
                        </TextDS2>
                        {/* <NotificationPriority
                        priority={item?.data?.priority_code}
                        text={item?.data?.priority}
                      /> */}
                        <NotificationStatus status={item?.data?.status_code} />
                      </div>
                      <TextDS2
                        color="Neutral/400"
                        agDesktop="Desktop/Body 3/Semi Bold"
                        agMobile="Desktop/Body 3/Semi Bold"
                      >
                        {item?.data?.obligation_name}
                      </TextDS2>
                      <Divider className="my-2" />
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {params.limit < params.total_records && (
                  <div
                    className="my-4 flex justify-center cursor-pointer"
                    onClick={handleShowMore}
                  >
                    <TextDS2
                      agDesktop="Desktop/Body 3/Bold"
                      agMobile="Desktop/Body 3/Bold"
                      color="Primary/Main"
                    >
                      Show More
                    </TextDS2>
                  </div>
                )}
              </div>
              <div className="w-[100%] flex justify-end">
                <ThemeButton
                  onClick={handleCloseModal}
                  className="text-white bg-primary-main rounded-[4px]"
                >
                  Close
                </ThemeButton>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalShowMore;
